.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
}
.header-title {
    font-size: 24px;
    color: #fff;
    margin: 0;
}
.header-list {
    display: flex;
    margin: 0;
    align-items: center;
    color: #fff;
    list-style-type: none;
}
.header-list li {
    margin-right: 20px;
    font-size: 18px;
}
.navbar {
    background-color: rgba(255, 255, 255, 0.5)
}