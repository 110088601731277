input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.5);
}
form {
  color: rgba(0,0,0,0.5);
  max-width: 400px;
  margin: 20px 20px 0 auto; 
}
.formwrapper {
  padding-left: 20px;
}
.findbtn{
  margin: 20px 0 !important;
  width: 100%;
}
.findbtnwrapper{
  padding-left: 20px;
}
.form-control {
  background-color: rgba(255,255,255,0.5) !important;
}
.carousel{
  max-width: 800px;
}
.carousel-inner{
  text-align: center;
}
.infoCard{
  padding:10px; 
  border-radius:30px; 
  margin: 20px 0 20px auto;
  max-width:450px; 
  color:#fff; 
  text-align:center;
}
.contactCard {
  padding:10px; 
  border-radius:30px; 
  margin: 20px 0;
  max-width:350px; 
  color:rgba(255,255,255,0.8); 
  background-color: rgba(0,0,0,0.3);
  text-align: center;
}
.bigInfo {
  border-radius:30px; 
  background-color: rgba(255,255,255,0.5);
  padding: 20px;
}
iframe { 
  border-radius:25px; 
}
img { 
  vertical-align: middle;
  max-width: 100%;
  max-height: 529px;
  height: auto;
 }
.btnimg {
  max-width: 300px;
  width: 100%;
}
.col-sm-4 {
  text-align: center;
}
  
input[type=submit] {
  width: 100%;
  background-color: rgba(0,0,0,0.3) !important;
  color: rgba(255,255,255,0.5);
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

li {
  margin: 20px;
}
a {
  color: rgba(0,0,0,0.5) !important;
}
a:hover {
  color: rgba(255,255,255,0.5) !important;
}
.btn {
  margin: 20px auto;
  border-radius: 30px !important;
  background-color: rgba(255,255,255,0.5) !important;
  border:0 !important;
}
.btn:hover {
  background-color: rgba(0,0,0,0.2) !important;
}
.justify-image{ text-align: justify;}
.justify-image:after{content:""; display: inline-block; width: 100%; height: 0;}
.about {
  margin: 20px auto 0 auto;
  color: rgba(0,0,0,0.7);
}
.aboutList {
  list-style: square outside;
}
.aboutContainer {
  max-width: 800px;
  margin: 0 auto;
}
.table {
  margin-bottom: 0 !important;
}
