ul {
    list-style: none;
}
.list-group-item {
    border-radius: 20px !important;
    background-color: rgba(255,255, 255, 0.5) !important;
    max-width: 840px;
    margin: 20px auto;
    color: rgba(0,0,0,0.6);
  }

.pictures {
    margin: 10px 0;
}
.errormsg {
  color: rgba(180, 0, 0, 0.5);
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  background-color: rgba(255,255, 255, 0.5);
  border-radius: 30px;
  padding: 20px;
}